import './TimeRangeForm.scss'
import { useForm, useFormState } from "react-hook-form";
import { format, isAfter, addHours, addDays, addMinutes } from 'date-fns'
import { yupResolver } from '@hookform/resolvers/yup';
import { string, date, object, bool, SchemaOf, ValidationError, number } from 'yup';
import React, { useEffect, useState } from "react";

export type TimeRangeData = {
  From: string
  To: string
  IsToNow: boolean
  IsFromN: boolean
  FromLastN: number
  FromLastNType: 'minutes' | 'hours' | 'days'
}

export interface Props {
  onSubmit: (data: TimeRangeData) => void
  isError: boolean
  isLoading: boolean
}

export interface State {
  UpdateToNowInterval: number | undefined
  IsNowChecked: boolean
}

export interface FormContext {
  isValid: boolean,
}

const dateTimeFormat = `yyyy-MM-dd'T'HH:mm`
const dateTimeFormatWithSeconds = `yyyy-MM-dd'T'HH:mm:ss`

const timeRangeDataSchema: SchemaOf<TimeRangeData> = object().shape({
  From: date().notRequired(),
  To: date().notRequired(),
  IsToNow: bool().notRequired(),
  IsFromN: bool().notRequired(),
  FromLastN: number().integer().positive().min(1).notRequired(),
  FromLastNType: string().notRequired()
})
.test('ValidationTest', (value: any): boolean | ValidationError | Promise<boolean | ValidationError> => {
  const data: TimeRangeData = value as TimeRangeData
  let from = new Date(value.From)
  let to = new Date(value.To)
  if (!data.IsFromN && !data.IsToNow && isAfter(from, to)) {
    return new ValidationError("'Beginn' muss vor 'Ende' liegen.", data.From, 'From', 'manual')
  } else if(data.IsFromN && data.FromLastN <= 0) {
    return new ValidationError("Zeitspanne muss positiv sein.", data.FromLastN, 'IsFromN', 'manual')
  }
  return true 
}).defined();

const TimeRangeForm: React.FC<Props> = (props: Props): JSX.Element => {

  const { register, handleSubmit, control, watch, setValue } = useForm<TimeRangeData, SchemaOf<TimeRangeData>>({
    reValidateMode: 'onChange',
    defaultValues: {
      From: format(addHours(new Date(), -8), dateTimeFormat),
      To: format(new Date() , dateTimeFormat),
      IsToNow: true,
      FromLastN: 8,
      FromLastNType: 'hours',
      IsFromN: true
    },
    resolver: yupResolver(timeRangeDataSchema),
    context: timeRangeDataSchema,
    shouldFocusError: true
  });

  //#region State

  const [ stateValue, setStateValue ] = useState<State>({
    UpdateToNowInterval: undefined,
    IsNowChecked: true
  })

  const { errors, isDirty }  = useFormState({ control });

  //#endregion

  // Lifecircle Mounted, Unmounted
  useEffect(() => {
    console.info('TimeRangeForm mounted')
    setValue('IsToNow', true, ({ shouldValidate: true, shouldDirty: true }))

    return () => {
      console.info('TimeRangeForm unmounted')
      clearInterval(stateValue.UpdateToNowInterval)
    }
  }, [setValue])

  const watchIsToNowChecked = watch('IsToNow', true)
  const watchIsFromNChecked = watch('IsFromN', true)

  useEffect(() => {
    setStateValue({...stateValue, IsNowChecked: watchIsToNowChecked})
    console.info('here')
  }, [ watchIsToNowChecked ])

  const onValid = (data: TimeRangeData, e: any) => {
    let exportObject = {
      From: '',
      To: data.To,
      FromLastN: data.FromLastN,
      FromLastNType: data.FromLastNType,
      IsFromN: data.IsFromN,
      IsToNow: data.IsToNow
    } as TimeRangeData

    if (isDirty) {
      if(data.IsToNow){
        exportObject.To = format(new Date(), dateTimeFormatWithSeconds)
      }
      if (data.IsFromN) {
        const fromN = data.FromLastN
        const type = data.FromLastNType
        switch (type) {
          case 'minutes':
            exportObject.From = format(addMinutes(new Date(exportObject.To), -1 * fromN), dateTimeFormatWithSeconds)
            break;
          case 'days':
            exportObject.From = format(addDays(new Date(exportObject.To), -1 * fromN), dateTimeFormatWithSeconds)
            break;
          case 'hours':
          default:
            exportObject.From = format(addHours(new Date(exportObject.To), -1 * fromN), dateTimeFormatWithSeconds)
            break;
        }
      } else {
        exportObject.From = data.From
      }
      props.onSubmit(exportObject)
    }
  }

  useEffect(() => {
    if (watchIsToNowChecked && !stateValue.UpdateToNowInterval) {
      const id: number = setInterval(() => {
        setValue('To', format(new Date(), dateTimeFormat), ({ shouldValidate: true, shouldDirty: true }))
      }, 1000) as any
      setStateValue({ ...stateValue, UpdateToNowInterval: id})
    } else {
      clearInterval(stateValue.UpdateToNowInterval)
      setStateValue({ ...stateValue, UpdateToNowInterval: undefined}) 
      setValue('To', format(new Date(), dateTimeFormat), ({ shouldDirty: true }))
    }
  }, [ watchIsToNowChecked ])

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <fieldset>
        <legend>Beginn</legend>
        <div className="input-group">
          <input 
            type="checkbox"
            id="IsFromN" {...register("IsFromN")}
            />
          <label htmlFor="IsFromN">Die letzten</label>
        </div>
        {
          watchIsFromNChecked &&
          <div className="input-group-container">
            <div className="input-group">
              <input 
                style={{
                  width: '50px'
                }}
                min="1"
                type="number"
                className={ errors.FromLastN ? 'error' : ''}
                title={ errors.FromLastN ? errors.FromLastN.message : ''}
                id="FromLastN" {...register("FromLastN")}
                />
            </div>
            <div className="input-group">
              <select 
                style={{ width: '150px' }}
                id="FromLastNType" {...register("FromLastNType")}>
                  <option value="minutes">Minuten</option>
                  <option value="hours">Stunden</option>
                  <option value="days">Tage</option>
                </select>
            </div>
          </div>
        }
        {
          !watchIsFromNChecked &&
          <div className="input-group">
          <input
            type="datetime-local"
            className={ errors.From ? 'error' : ''}
            title={ errors.From ? errors.From.message : ''}
            id="From" {...register("From")}
            />
        </div>
        }
      </fieldset>
      <fieldset>
      <legend>Ende</legend>
        <div className="input-group">
          <input 
            type="checkbox"
            className={ errors.IsToNow ? 'error' : ''}
            id="IsToNow" {...register("IsToNow")}
            />
          <label htmlFor="IsToNow">Bis jetzt</label>
        </div>
        {
          <div className="input-group">
            <input
              disabled={stateValue.IsNowChecked}
              type="datetime-local"
              className={ errors.To ? 'error' : ''}
              id="To" {...register("To")}
              />
          </div>
        }
      </fieldset>
      <div className='button-wrapper'>
        <button type="submit" className="btn enabled primary"><i className="fa fa-check"></i></button>
      </div>
      {/* {
        errors.From && <span>
          {errors.From?.message}
        </span>
      } */}
    </form>
  )
}

export default TimeRangeForm;