import { CSSProperties, FC, PropsWithChildren } from "react"
import Loading from "../LoadingAnimation/LoadingAnimation";

export interface WidgetProps {
  title: string,
  isLoading: boolean,
  isLoadingError: boolean,
  style?: CSSProperties
}

const WidgetComponent : FC<WidgetProps> = (props: PropsWithChildren<WidgetProps>) => {
  
  return (
    <div>
      <header style={{
            backgroundColor: 'white',
            display: 'flex',
            height: 30,
            padding: '0 10px',
            boxSizing: 'border-box',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: 'linear-gradient(-200deg, rgba(255,255,255,1) 0%, rgba(141,143,145,1) 100%)'
          }}>
              <span style={{
                fontWeight: 'bold'
              }}>
                { 
                  props.title
                }
              </span>
              <Loading style={{ color: 'white' }}
                isLoading={ props.isLoading }
                isError={ props.isLoadingError }
                isSuccess={ !props.isLoading && !props.isLoadingError } 
              />
      </header>
      <div className="scrollbar native" style={props.style} >
        { props.children }
      </div>
    </div>
  )
}

export default WidgetComponent;