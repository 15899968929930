import { SankeyGraph } from "d3-sankey";
import { IInternalLink, IInternalNode } from "./D3Models";
import { ID3SankeyData, ID3SankeyLink, ID3SankeyNode } from "./Models";

export default class D3SankeyModelMapper {
  public static mapDataToInternalData(data: ID3SankeyData): SankeyGraph<IInternalNode, IInternalLink> {
    return {
      nodes: data.Nodes.map(e => this.mapNodeToInternalNode(e)),
      links: data.Links.map(e => this.mapLinkToInternalLink(e))
    }
  }

  public static mapLinkToInternalLink(link: ID3SankeyLink): IInternalLink {
    return {
      id: link.Id,
      name: link.Name,
      source: link.Source,
      target: link.Target,
      value: Math.abs(link.Value),
      secondaryValue: link.SecondaryValue,
      valueType: link.ValueType,
      hasError: link.HasError,
      isValueRelationMaster: link.IsValueRelationMaster
    } as IInternalLink
  }

  public static mapNodeToInternalNode(node: ID3SankeyNode): IInternalNode {
    return {
      name: node.Name,
      customDepth: node.Depth,
      customSort: node.Sort,
      valueType: node.ValueType,
      showName: node.SankeyShowName
    } as IInternalNode
  }
}