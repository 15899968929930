 import { useNavigate } from 'react-router-dom';
import Button from '../Button';

export interface Props {
  title: string,
  titleRight: string,
}

const AppHeader: React.FC<Props> = (props: Props): JSX.Element => {
  const nav = useNavigate();
 
  const OnLogout = async (): Promise<void> => nav('/logout')

  return (
    <header>
      <div className="header-left">
        <img className="logo" src={`${process.env.PUBLIC_URL}/samsoric-logo-white.png`} alt=""/>
        <h1>
          { props.title }
        </h1>
      </div>
      <div className="header-right">
        <span>
          { props.titleRight }
        </span>
        <Button 
          icon={"fa fa-sign-out-alt"}
          onClick={ OnLogout }
          isDisabled={false}
          title="Abmelden"
          style={{
            color: 'white'
          }}/>
      </div>
    </header>
  )
}

export default AppHeader;