import { SyntheticEvent } from 'react'
import { useForm } from "react-hook-form";
import './LoginForm.scss'

export interface LoginData {
  Username: string
  Password: string
}

export interface LoginEvent extends SyntheticEvent<LoginData> {
  Data: LoginData
}

export interface Props {
  onSubmit: (data: LoginData) => void
  isError: boolean
  isLoading: boolean
}

const LoginForm: React.FC<Props> = (props: Props) => {

  const { onSubmit, isError, isLoading } = props;

  const { 
    register,
    handleSubmit,
    // formState: { errors }
  } = useForm<LoginData>();

  const submitForm = (data: LoginData) => {
    onSubmit(data)
  }

  return (
    <form className="form-login" onSubmit={handleSubmit(submitForm)}>
      <strong className="title">Login</strong>
      <div className="input-group col">
        <input type="text" id="username" style={{ width: '100%' }} autoFocus={ true } {...register("Username", ({ required: true }))}/>
        <label htmlFor="username">Benutzername</label>
      </div>
      <div className="input-group col">
        <input type="password" id="password" style={{ width: '100%' }} {...register("Password", { required: true } )}/>
        <label htmlFor="password">Passwort</label>
      </div>
      { isError && (<span className="color-error">Anmeldung fehlgeschlagen.</span>)} 
      { isLoading && (<span className="color-info">Anmeldung läuft ...</span>)} 
      <button className="btn enabled btn-submit" type="submit"><i className="fa fa-check"></i></button>
    </form>
  )
}

export default LoginForm