import React, { useEffect, useState } from "react"
import './Navigation.scss'
import Button from '../Button'
import { useNavigate } from "react-router-dom"
import LoginContextProvider, { useLoginContext } from "../../store/LoginContext"

export interface NavigationButton {
  icon: string
  title: string
  link: string
  isAvailable: boolean
  showLoggedOut: boolean
  showLoggedIn: boolean
  loggedInElevatedOnly: boolean
  showInHeader: boolean
  showInSidebar: boolean
}

export interface NavigationProps {
  groups: Array<NavigationButton[]>
}

export interface NavigationState {
  groups: Array<NavigationButton[]>
}

const Navigation: React.FC<NavigationProps> = (props: NavigationProps): JSX.Element => {
  const { groups } = props;
  const nav = useNavigate();
  const [iGroups, setIGroups] = useState<Array<NavigationButton[]>>([])
  const { IsLoggedIn, IsElevated } = useLoginContext();

  useEffect(() => {
    setIGroups(groups)
  }, [groups])

  const NavigationClicked = async (page: string): Promise<void> => {
    nav(`/${page}`)
  }

  const OnMenuClicked = async (): Promise<void> => {

  }

  return (
    <LoginContextProvider>
        <nav>
          <header>
            <Button icon={"fa fa-bars"} onClick={OnMenuClicked} isDisabled={false} title={""}></Button>
          </header>
          <section>
            {
              iGroups &&
              iGroups.map((group: NavigationButton[], index) => (
                <div key={index} className="navigation-group">
                  {
                    group.map((button: NavigationButton, index) => (
                      (
                        (!IsLoggedIn && button.showLoggedOut) ||
                        (IsLoggedIn && button.showLoggedIn && !button.loggedInElevatedOnly) ||
                        (IsLoggedIn && (IsElevated || false) && button.loggedInElevatedOnly)
                      )
                      && <Button key={button.link} icon={button.icon} onClick={() => NavigationClicked(button.link)} isDisabled={!button.isAvailable} title={button.title}></Button>
                    ))
                  }
                </div>
              ))
            }
          </section>
        </nav>
    </LoginContextProvider>
  )
}

export default Navigation;