import axios, { AxiosResponse } from "axios"
import IUserData from "../components/Models/IUserLoginResult";
import AzureFunctionsServiceBase from "./ApiServiceBase";

export default class LoginService extends AzureFunctionsServiceBase {
 
  public async HandleLogin(username: string, password: string): Promise<IUserData> {

    const query = `${this.API_URL}/Login`;

    const dto = {
      username,
      password
    }

    try {
      const result: AxiosResponse<IUserData> = await axios.post(query, dto, { params: this.queryParams });
      return result.data;
    } catch (error) {
      throw new Error("Login fehlgeschlagen.")
    }
  }
}