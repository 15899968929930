export default class AzureFunctionsServiceBase {
  public API_URL: string = process.env.REACT_APP_API_URL!
  public API_SECRET: string = process.env.REACT_APP_API_KEY!

  public queryParams: any = {}

  constructor() {
    if(this.API_SECRET) {
      this.queryParams = {...this.queryParams, code: this.API_SECRET}
    }
  }
}