import { useState } from "react";
import LoginForm, { LoginData } from "../../components/LoginForm/LoginForm";
import { LoginService } from "../../api";
import './LoginView.scss';
import LoginContextProvider, { useLoginContext } from "../../store/LoginContext";
import { useNavigate } from "react-router-dom";

export interface State {
  isLoading: boolean
  isLoginError: boolean
}

const LoginView: React.FC = () => {
  const nav = useNavigate();
  
  const { SetUserData, IsLoggedIn } = useLoginContext();

  const [ isLoading, setIsLoading ] = useState(false)
  const [ isLoginError, setIsLoginError ] = useState(false)

  const submitForm = async (data: LoginData): Promise<void> => {
    setIsLoading(true)
    setIsLoginError(false)

    const loginService = new LoginService();
    try {
      var result = await loginService.HandleLogin(data.Username, data.Password)
      SetUserData(result)
      nav('/')
    } catch {
      setIsLoading(false)
      setIsLoginError(true)

      setTimeout(() => {
        setIsLoading(false)
        setIsLoginError(false)
      }, 3000);
    } finally {
      setIsLoading(false)
      setIsLoginError(isLoginError)
    }
  }

  return (
    <LoginContextProvider>
      <main className="view-login">
        <div className="login-container">
          <LoginForm 
            onSubmit={ (data) => submitForm(data) }
            isError={ isLoginError }
            isLoading={ isLoading }
          />
        </div>
        <span>
        { JSON.stringify(IsLoggedIn, null, 2) }
        </span>
        {/* <img src={`${process.env.PUBLIC_URL}/samsoric-honeycomb.png`} width="150"/> */}
        <img className="logo" src={`${process.env.PUBLIC_URL}/samsoric-logo.png`} alt=""/>
      </main>
    </LoginContextProvider>
  )
}

export default LoginView