import { addMinutes, addDays, addHours } from "date-fns";
import { useCallback, useEffect, useRef, useState} from "react";
import TimeRangeForm from "../../components/Forms/TimeRange";
import { TimeRangeData } from "../../components/Forms/TimeRange/TimeRangeForm";
import { ISankeyDiagram } from "../../components/Models/ISankey";
import { GraphServices } from "../../api";
import { ID3SankeyConfig, ID3SankeyData } from "../../Diagrams/D3/D3Sankey";
import WidgetComponent from "../../components/widgets/WidgetComponent";
import SankeyGraph from "../../components/widgets/diagrams/SankeyGraph";
import { ISankeyDiagramContext, SankeyDiagramContext } from "../../store/SankeyDiagramContext";
import LoginContextProvider, { useLoginContext } from "../../store/LoginContext";
import useAsyncEffect from 'use-async-effect'

export interface Props {
}

export interface State {
  from: Date
  until: Date
  isToNow: boolean
  isFromN: boolean
  fromLastN: number
  fromLastNType: 'minutes' | 'hours' | 'days'

  diagram: ISankeyDiagram
  sankeyDefinition: ID3SankeyConfig
  sankeyIsLoading: boolean
  sankeyIsError: boolean
  sankeyValueRelationMaster?: string

  isTimeRangeFormError: boolean
  isTimeRangeFormLoading: boolean
}

interface TimeRangeState {
  IsLoading: boolean,
  IsError: boolean,
  IsToNow: boolean,
  IsFromN: boolean, 
  From: Date,
  Until: Date,
  FromLastN: number,
  FromLastNType: 'days' | 'hours' | 'minutes' | 'seconds',
}

interface SankeyState {
  isLoading: boolean,
  isError: boolean,
  sankeyValueRelationMaster: string | undefined,
  setSankeyValueRelationMaster: (id: string | undefined) => void,
  config: ID3SankeyConfig
}

const dateTimeFormatWithSeconds = `yyyy-MM-dd'T'HH:mm:ss`

const DashboardView: React.FC<Props> = (props: Props): any => {

  var interval: any = useRef(null)

  const { CompanyId, UserData } = useLoginContext();

  //#region States

  const [ dashboardState, setDashboardState ] = useState({})

  const [ timeRangePickerState, setTimeRangePickerState ] = useState({
    IsLoading: false,
    IsError: false,
    IsToNow: true,
    IsFromN: true, 
    From: new Date(),
    Until: new Date(),
    FromLastN: 8,
    FromLastNType: 'hours',
  } as TimeRangeState)

  const setSankeyValueRelationMaster = (id: string | undefined) => {
    setSankeyState({
      ...sankeyState,
      sankeyValueRelationMaster: id
    })
  }

  const [ sankeyState, setSankeyState ] = useState({
    isLoading: false,
    isError: false,
    sankeyValueRelationMaster: undefined,
    config: {
      Height: 500,
      Width: 1400,
      FontSizeNode: '10pt',
      FontSizeLink: '11pt',
      MarginTop: 15,
      MarginBottom: 10,
      MarginLeft: 20,
      MarginRight: 30,
      NodeWidth: 10,
      NodePadding: 50
    } as ID3SankeyConfig
  } as SankeyState)

  const [sankeyTitle, setSankeyTitle] = useState<string | undefined>("-")

  const [ sankeyDiagram, setSankeyDiagram ] = useState(
    {
      Nodes: [],
      Links: []
    } as ID3SankeyData)

  //#endregion


  //#region LifeCircle

  /**
   * Mount & UnMount
   */
  useAsyncEffect(() => {
    // Anything in here is fired on component mount.
    console.info('Dashboard mount')
    interval.current = setInterval(async () => {
      console.info('Dashboard interval fire')
      await loadData();
    }, 10000)

    return() => {
      // Anything in here is fired on component unmount.
      console.info('Dashboard unmount')
      clearInterval(interval.current);
      interval.current = null;
    }
  }, [])
  
  //#endregion

  useAsyncEffect(async () => {

    if(sankeyState.isLoading){
      console.info('jumping over updateSankeyDiagram circle')
      return undefined
    }
    await loadData();
  }, [ CompanyId, UserData, sankeyState.sankeyValueRelationMaster, timeRangePickerState ]);

  const loadData = async (): Promise<void> => {
    console.info('loadData')
    setSankeyState({
      ...sankeyState,
      isLoading: true,
      isError: false
    })

    try {
      const service = new GraphServices.SankeyService();

      let from: Date
      let to: Date

      if (timeRangePickerState.IsToNow) {
        to = new Date()
      } else {
        to = timeRangePickerState.Until
      }
      if (timeRangePickerState.IsFromN) {
        const fromN = timeRangePickerState.FromLastN
        const type = timeRangePickerState.FromLastNType
        switch (type) {
          case 'minutes':
            from = addMinutes(to, -1 * fromN)
            break;
          case 'days':
            from = addDays(to, -1 * fromN)
            break;
          case 'hours':
          default:
            from = addHours(to, -1 * fromN)
            break;
        }
      } else {
        from = new Date(timeRangePickerState.From)
      }

      const diagram = await service.GetData(CompanyId!, UserData!.ProductionUnitIds[0], from, to, sankeyState.sankeyValueRelationMaster)
      setSankeyTitle(diagram.Title)
      setSankeyDiagram(diagram as ID3SankeyData)
      setSankeyState({ ...sankeyState, isError: false, isLoading: false})
    } catch(ex) {
      setSankeyState({ ...sankeyState, isError: true, isLoading: false })
      console.error(ex)
      throw(ex)
    }
  }

  const onTimeRangeFormSubmit = (data: TimeRangeData): void => {
    setTimeRangePickerState({
      ...timeRangePickerState,
      From: new Date(data.From),
      Until: new Date(data.To),
      IsFromN: data.IsFromN,
      IsToNow: data.IsToNow,
      FromLastN: data.FromLastN,
      FromLastNType: data.FromLastNType
    })
  }

  return (
      <LoginContextProvider>
        <main className="scrollbar">
          <section className="widget">
            <header>
              Beobachtungszeitraum
            </header>
            <TimeRangeForm
              isError={ timeRangePickerState.IsError }
              isLoading={ timeRangePickerState.IsLoading }
              onSubmit={ (data) => onTimeRangeFormSubmit(data) }/>
          </section>
          <section className="widget">
            <WidgetComponent title={sankeyTitle || '-'} isLoading={sankeyState.isLoading} isLoadingError={sankeyState.isError} style={{ height: sankeyState.config.Height, backgroundColor: 'white' }}>
              <SankeyGraph 
                sankeyValueRelationMaster={ sankeyState.sankeyValueRelationMaster }
                data={sankeyDiagram}
                onSetSankeyValueRelationMaster={ setSankeyValueRelationMaster }
                isLoading={ sankeyState.isLoading }
                isError={ sankeyState.isError }
                config={ sankeyState.config }
              />
            </WidgetComponent>
          </section>
        </main>
    </LoginContextProvider>
  )
}

export default DashboardView;
