import React, { createContext, PropsWithChildren, useState } from "react";
import IUserData from "../components/Models/IUserLoginResult";

const userDataKey = 'userdata';

export interface LoginState {
  UserData?: IUserData
  UserName?: string
  CompanyId?: string
  CompanyName?: string
  IsLoggedIn: boolean
  IsElevated: boolean

  SetUserData: (value: IUserData) => void
  Logout: () => void
}

const defaultLoginState: LoginState = {
  IsLoggedIn: false,
  IsElevated: false,
  SetUserData: () => {},
  Logout: () => {}
}

const LoginContext = createContext<LoginState>(defaultLoginState);

const LoginContextProvider: React.FC<PropsWithChildren<{}>> = (props: PropsWithChildren<{}>): JSX.Element => {

  const OnLogout = (): void => {
    localStorage.removeItem(userDataKey)
    setState({
      ...state,
      IsLoggedIn: false
    });
  }

  const OnSetUserData = (userData: IUserData): void => {
    const newState: LoginState = {
      ...state,
      UserData: userData,
      UserName: userData.Username,
      IsLoggedIn: true,
      IsElevated: userData.IsElevatedUser,
      CompanyId: userData.CompanyId,
      CompanyName: userData.CompanyName
    };

    setState({
      ...state,
      ...newState
    })

    localStorage.setItem("userdata", JSON.stringify(newState))
  }

  const [state, setState] = useState<LoginState>({
    ...(localStorage.getItem("userdata") ? JSON.parse(localStorage.getItem("userdata")!) : defaultLoginState),
    SetUserData: OnSetUserData,
    Logout: OnLogout
  });

  return(
    <LoginContext.Provider value={state}>
      { props.children }
    </LoginContext.Provider>
  )
};

export default LoginContextProvider;

export const useLoginContext = () => React.useContext(LoginContext);