import { createRef, RefObject, useEffect, useRef, useState } from "react";
import D3SankeyPainter from "./D3SankeyPainter";
// import D3SankeyPainter from "./D3SankeyPainter";
import { ID3SankeyConfig, ID3SankeyData } from "./Models";

export default function useD3Sankey(root: RefObject<HTMLDivElement>, onLinkClicked?: (id?: string) => void) {

  const [ painter, setPainter ] = useState<D3SankeyPainter | undefined>();

  const [ d3SankeyData, setD3SankeyData ] = useState({
    Links: [],
    Nodes: []
  } as ID3SankeyData)

  const [ canPaint, setCanPaint ] = useState(false);

  useEffect(() => {
    if(root.current){
      setPainter(new D3SankeyPainter(root.current, onLinkClicked))
    }
  }, [root.current])

  useEffect(() => {
    const isLinks = d3SankeyData.Links && d3SankeyData.Links.length > 0;
    const isNodes = d3SankeyData.Nodes && d3SankeyData.Nodes.length > 0;
    const canPaint = isLinks && isNodes;
    setCanPaint(canPaint)
  }, [d3SankeyData])

  const [ d3SankeyConfig, setD3SankeyConfig ] = useState({
    MarginTop: 0,
    MarginRight: 0,
    MarginBottom: 0,
    MarginLeft: 0,
    Height: 100,
    Width: 100,
    NodeWidth: 20,
    NodePadding: 50,
    FontSizeNode: '12pt',
    FontSizeLink: '12px'
  } as ID3SankeyConfig)

  // update 
  useEffect(() => {
    if(painter)
      painter.updateLayout(d3SankeyConfig)
  }, [
    d3SankeyConfig.Height, d3SankeyConfig.MarginTop, d3SankeyConfig.MarginBottom,
    d3SankeyConfig.Width, d3SankeyConfig.MarginLeft, d3SankeyConfig.MarginRight
  ])

  useEffect(() => {
    if (canPaint && painter) {
      painter.drawChart(d3SankeyData)
    }
  }, [ canPaint, d3SankeyData ])

  return {
    d3SankeyData, setD3SankeyData,
    d3SankeyConfig, setD3SankeyConfig
  } as const
}