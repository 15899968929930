import { CSSProperties, useEffect, useState } from "react"
import './Button.scss'

export interface ButtonProps {
  icon?: string
  text?: string
  title?: string

  isActive?: boolean
  isDisabled?: boolean
  isLoading?: boolean

  onClick?: () => Promise<void>

  style?: CSSProperties
}

const Button: React.FC<ButtonProps> = (props: ButtonProps): JSX.Element => {

  const { icon, text, title, isActive, isDisabled, isLoading, onClick, style } = props;

  const [ iIcon, setIIcon ] = useState<string | undefined>('')

  const [ buttonClassNames, setButtonClassNames ] = useState<Array<string>>(['btn'])

  //#region Lifecycle

  useEffect(() => {
    setButtonClassNames([
      'btn',
      onClick && !isDisabled ? 'enabled' : 'disabled',
      isActive ? 'active' : 'inactive',
      isLoading ? 'loading' : ''
    ])
  }, [isLoading, isDisabled, isActive, onClick])

  useEffect(() => {
    setIIcon(isLoading ? 'fa fa-spinner' : icon ?? undefined);
  }, [
    isLoading,
    icon
  ])
  
  //#endregion

  const handleOnButtonClick = async (args: React.MouseEvent): Promise<void> => {
    if(onClick && !isDisabled) {
      await onClick()
    }
  }

  return (
    <div style={ style } title={ title ?? '' } className={ buttonClassNames.join(' ') } onClick={ handleOnButtonClick }>
      { icon && <i className={ iIcon }></i> }
      { text && <span>{text}</span> }
    </div>
  )
}

export default Button;