import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginContextProvider, { useLoginContext } from "../../store/LoginContext";

const LogoutView: React.FC = (): JSX.Element =>  {
  const nav = useNavigate();

  const { Logout } = useLoginContext();

  useEffect(
    () => {
      Logout();
      nav('/')
    }
  )

  return (
    <LoginContextProvider>
      <main>
        Abmelden ...
      </main>
    </LoginContextProvider>
  )
}

export default LogoutView