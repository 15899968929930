import { createRef, RefObject, useEffect } from "react";
import useD3Sankey, { ID3SankeyConfig, ID3SankeyData } from "../../../../Diagrams/D3/D3Sankey";

export interface Props {
  onSetSankeyValueRelationMaster: (id: string | undefined) => void
  isLoading: boolean,
  isError?: boolean,
  sankeyValueRelationMaster: string | undefined,
  data: ID3SankeyData,
  config: ID3SankeyConfig
}

export type { ID3SankeyConfig }

const SankeyGraph : React.FC<Props> = (props: Props) => {
  const myRef: RefObject<HTMLDivElement> = createRef();

  const {
    onSetSankeyValueRelationMaster,
    config,
    data
  } = props

  const {
    setD3SankeyData,
    setD3SankeyConfig
  } = useD3Sankey(myRef, onSetSankeyValueRelationMaster)

  useEffect(() => {
    setD3SankeyData(data)
  }, [data])

  useEffect(() => {
    setD3SankeyConfig(config)
  }, [config])

  return(
    <div>
        <div className="scrollbar native" style={{
            width: '100%',
            height: config.Height + config.MarginTop + config.MarginBottom,
            overflowX: 'auto',
            overflowY: 'hidden',
            position: 'absolute'
          }}>
          <div ref={myRef}></div>
        </div>
    </div>
  )
}

export default SankeyGraph;

// export default class SankeyWidget extends Component<Props, State> {
//   private myRef: RefObject<HTMLDivElement> = createRef();
//   private painter?: D3SankeyPainter

//   constructor(props: Props) {
//     super(props);
//     this.state = {
//       IsLoading: false,
//       Title: ''
//     }
//   }

//   public update(definition: ISankeyDefinition, data: ISankeyDiagram): void {
//     if(data.Title){
//       this.setState({...this.state, Title: data.Title})
//     }

//     if(this.myRef.current) {
//       this.painter = new D3SankeyPainter(this.myRef.current, definition, this.onSankeyDiagramLinkClicked.bind(this))
//     }

//     if(this.painter) {
//       this.painter.drawChart({ Nodes: data.Nodes, Links: data.Links } as Data)
//     }
//   }

//   private onSankeyDiagramLinkClicked(id: string): void {
//     this.props.onLinkClicked(id)
//   }

//   render() {
//     const { isLoading, isLoadingError, definition } = this.props
//     const { Title } = this.state
//     return (
//         <div style={{
//           width: '100%',
//           overflowY: 'hidden',
//           overflowX: 'hidden',
//           position: 'relative',
//           height: definition.Height + definition.MarginTop + definition.MarginBottom + 30,
//         }}>
//           <header style={{
//             backgroundColor: 'white',
//             display: 'flex',
//             height: 30,
//             padding: '0 10px',
//             boxSizing: 'border-box',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//             background: 'linear-gradient(-200deg, rgba(255,255,255,1) 0%, rgba(141,143,145,1) 100%)'
//           }}>
//               <span style={{
//                 fontWeight: 'bold'
//               }}>
//                 { 
//                   Title
//                 }
//               </span>
//               <Loading style={{
//                 color: 'white'
//               }}
//                 isLoading={ isLoading }
//                 isError={ isLoadingError }
//                 isSuccess={ !isLoading && !isLoadingError }></Loading>
//           </header>
//           {
//             this.props && (
//               <div className="scrollbar native" style={{
//                 width: '100%',
//                 height: definition.Height + definition.MarginTop + definition.MarginBottom,
//                 overflowX: 'auto',
//                 overflowY: 'hidden',
//                 position: 'absolute'
//               }}>
//                 <div ref={this.myRef}></div>
//               </div>
//             )
//           }
//       </div>
//     )
//   }
// }
