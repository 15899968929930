import './LoadingAnimation.scss'

export interface Props {
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  style: object
}

export interface State {

}

export default function LoadingAnimation(props: Props, state: State) {
  const { isLoading, isError, isSuccess, style } = props

  return (
    <span className="loading-animation" style={style}>
      {
        isSuccess && (
          <i className="fa fa-check"></i>
        )
      }
      {
        isError && (
          <i className="fa fa-exclamation-triangle" style={{
            color: 'var(--color-error)'
          }}></i>
        )
      }
      {
        isLoading && !isError && (
            <i className="spinning fa fa-spinner"></i>
        )
      }
      </span>
  )
}