import axios, { AxiosResponse } from "axios"
import { formatISO } from "date-fns"
import { ISankeyDiagram } from "../../components/Models/ISankey"
import AzureFunctionsServiceBase from "../ApiServiceBase"

export default class SankeyService extends AzureFunctionsServiceBase {

  public async GetData(CompanyId: string, ProductionUnitId: string, from?: Date, to?: Date, valueRelationMasterId?: string): Promise<ISankeyDiagram> {

    if(valueRelationMasterId){
      this.queryParams = {...this.queryParams, showValuesInRelationTo: valueRelationMasterId}
    }

    if(from){
      const fromString = formatISO(from)
      this.queryParams = {...this.queryParams, from: fromString}
    }
    
    if(to){
      const toString = formatISO(to)
      this.queryParams = {...this.queryParams, to: toString}
    }

    if(this.API_SECRET){
      this.queryParams = {...this.queryParams, code: this.API_SECRET}
    }

    const query = `${this.API_URL}/company/${CompanyId}/productionUnit/${ProductionUnitId}/diagram/sankey`
    const response: AxiosResponse<ISankeyDiagram> = await axios.get(query, { params: this.queryParams })
    return response.data;
  }
}