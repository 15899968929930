import { select, Selection } from "d3"
import {
  sankey,
  SankeyNode,
  SankeyGraph,
  SankeyLayout,
  sankeyLinkHorizontal,
  sankeyJustify
} from 'd3-sankey'

import D3SankeyModelMapper from "./Mapper"
import { ID3SankeyConfig, ID3SankeyData } from "./Models"
import { IInternalLink, IInternalNode } from "./D3Models"

export default class D3SankeyPainter {
  private Margin = { Top: 0, Right: 0, Bottom: 0, Left: 0 }
  private Dimensions = { Height: 100, Width: 100 }
  private NodeDefinition = { Width: 20, Padding: 50 }
  private FontSizeNode = '12pt'
  private FontSizeLink = '12pt'

  private _container: HTMLDivElement
  private _svg?: Selection<SVGSVGElement, unknown, null, unknown>

  private generator?: SankeyLayout<SankeyGraph<{}, {}>, {}, {}>

  private onClickCallback?: (id: string) => void;

  constructor(containerElementRef: HTMLDivElement, onclick?: (id: string) => void) {
    this._container = containerElementRef
    this.onClickCallback = onclick
  }

  public updateLayout(definition: ID3SankeyConfig) {
    this.FontSizeNode = definition.FontSizeNode
    this.FontSizeLink = definition.FontSizeLink
    this.Margin = {
      Top: definition.MarginTop,
      Right: definition.MarginRight,
      Bottom: definition.MarginBottom,
      Left: definition.MarginLeft,
    }
    this.Dimensions = {
      Height: definition.Height,
      Width: definition.Width
    }
    this.NodeDefinition = {
      Padding: definition.NodePadding,
      Width: definition.NodeWidth
    }
    this.setUpGraphLayout()
    this.setUpDiagramLayout()
  }

  private setUpGraphLayout() {
    select(this._container).select('svg').remove()
    this._svg = select(this._container)
      .append('svg')
      .attr('height', this.Dimensions.Height + this.Margin.Top + this.Margin.Bottom)
      .attr('width', this.Dimensions.Width + this.Margin.Left + this.Margin.Right)

    this._svg
      .append('g')
      .attr('transform', `translate(${this.Margin.Left}, ${this.Margin.Top})`)
  }

  private setUpDiagramLayout() {
    this.generator = sankey()
      .nodeWidth(this.NodeDefinition.Width)
      .nodePadding(this.NodeDefinition.Padding)
      .size([this.Dimensions.Width, this.Dimensions.Height])
  }

  public drawChart(data: ID3SankeyData) {
    // Drop previous svg if exists
    select(this._container)
      .select('svg')
      .remove()

    const graph = D3SankeyModelMapper.mapDataToInternalData(data);

    // Create new svg
    let svg = select(this._container)
      .append('svg')
      .attr('height', this.Dimensions.Height + this.Margin.Top + this.Margin.Bottom)
      .attr('width', this.Dimensions.Width + this.Margin.Left + this.Margin.Right);

    // Create sankey generator
    const { nodes, links } = sankey<SankeyGraph<IInternalNode, IInternalLink>, IInternalNode, IInternalLink>()
      .nodeWidth(this.NodeDefinition.Width)
      .nodePadding(this.NodeDefinition.Padding)
      .nodeAlign((nd: IInternalNode, n: number) => {
        return nd.customDepth !== undefined ? nd.customDepth : sankeyJustify(nd, n)
      })
      .nodeSort((a: IInternalNode, b: IInternalNode): number => {
        if(a.customSort !== undefined && b.customSort !== undefined){
          return a.customSort! > b.customSort! ? 1 : -1;
        }
        return 1
      })
      .extent(
        [
          [this.Margin.Left, this.Margin.Top], 
          [this.Dimensions.Width - this.Margin.Left - this.Margin.Right, this.Dimensions.Height - this.Margin.Top - this.Margin.Bottom]
        ])(graph);

    // configure link styling
    let link = svg
      .append("g")
        .attr('class', 'links')
        .attr("fill", "none")
        .attr('stroke', '#000')
        .attr("stroke-opacity", 0.1);

    // add sankey calculated link-lines
    link
      .selectAll('path')
      .data(links)
      .enter()
        .append('path')
        .attr('d', sankeyLinkHorizontal())
        .attr('stroke-width', function(d: any) { return Math.max(1, d.width); })
        .attr('stroke', (d: IInternalLink): string => { 
          return d.hasError 
            ? 'red' 
            : !d.valueType 
              ? '#999'
              : d.valueType === "calculated" 
                ? 'blue' 
                : d.valueType === "hardcoded_relative" 
                  ? 'orange' 
                    : d.valueType === "measurement"
                    ? 'green' 
                  : '#999' })
        .on('click', (event, d: IInternalLink) => {
          if(this.onClickCallback) {
            this.onClickCallback(d.id!)            
          }
        })

        // .sort(function(a: any, b: any) { return b.dy - a.dy})
  
    // add nodes rectancles
    svg.append('g')
      .attr('class', 'nodes')
      .selectAll('g')
      .data(nodes)
      .enter()
        .append('rect')
        .attr("x", (d: IInternalNode) : number => { return d!.x0!; })
        .attr("y", (d: IInternalNode): number => { return d.y0!; })
        .attr("height", (d: IInternalNode): number => {
          const height = d.y1! - d.y0! 
          return height <= 1 ? 5 : height;
        })
        .attr("width", (d: IInternalNode): number => { return d.x1! - d.x0!; })
        .attr('fill', 'white')
        .attr('stroke-width', 1)
        .attr('stroke', '#999')

    // Append node lables
    svg.append("g")
        .attr("font-family", "sans-serif")
        .attr("font-size", this.FontSizeNode)
        .attr('font-weight', 'bold')
        .attr('fill', 'gray')
        .selectAll("text")
      .data(nodes)
        .join("text")
          .attr("text-anchor", (d: IInternalNode) => d.x0! < (this.Dimensions.Width - this.Margin.Left - this.Margin.Right) / 2 ? "start" : "end")
          .text((d: IInternalNode) => d.showName ? d.name : '')
          .attr("x", (d: SankeyNode<IInternalNode, IInternalLink>, index: number, groups: any): number => {
            const textLength = groups[index].getBoundingClientRect().width;
            return d.x0! < (this.Dimensions.Width - this.Margin.Left - this.Margin.Right) / 2 ? d.x1! - (this.NodeDefinition.Width / 2) - (textLength / 2): d.x0! + (textLength / 2) + (this.NodeDefinition.Width / 2)
          })
          .attr("y", (d: SankeyNode<IInternalNode, IInternalLink>, index: number, groups: any): number => (d.y1!))
          .attr("dy", "1.5em");
  
    // Append link lables
    svg.append("g")
        .attr("font-family", "sans-serif")
        .attr("font-size", this.FontSizeLink)
      .selectAll("text")
      .data(links)
      .join("text")
        .text((d: IInternalLink, index: number, groups: any) => `${d.secondaryValue && (d.secondaryValue as any) as string !== 'Infinity' ? `${d.secondaryValue.toFixed(1)} %` : `${d.value.toFixed(2)}`}`)
          .attr('font-weight', (d: IInternalLink, index: number, groups: any) => d.isValueRelationMaster ? 'bold' : '')
          .attr("dy", "0.5em")
          .attr('fill', (d: IInternalLink): string => { 
            return d.hasError 
              ? 'red' 
              : 'black'
          })
          .attr("x", (d: IInternalLink, index: number, groups: any): number => {
            const textLength = groups[index].getBoundingClientRect().width;
            let source: IInternalNode = d.source as IInternalNode
            let target: IInternalNode = d.target as IInternalNode
            let x = ((target.x0! + source.x1!) / 2) - (textLength / 2)
            return x
          })
          .attr("y", (d: IInternalLink, index: number, groups: any): number => {
            const y = (d.y0! + d.y1!) / 2 - 1
            return y
          });

    return svg.node();
  }

}