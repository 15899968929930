import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Navigation from '../Navigation';
import DashboardView from '../../views/DashboardView';
import { NavigationButton } from '../Navigation/Navigation';
import LoginView from '../../views/LoginView';
import LogoutView from '../../views/LogoutView';

import './App.scss';
import './reset.scss';
import './Widget.scss';
import './Forms.scss';
import './Scrollbar.scss';
import AppHeader from '../AppHeader';
import { useLoginContext } from '../../store/LoginContext';

const navigationButtonGroups: NavigationButton[][] = [
  [
    {
      icon: 'fa fa-th-large',
      title: 'Dashboard',
      isAvailable: true,
      link: 'dashboard',
      showLoggedOut: false,
      showLoggedIn: true,
      loggedInElevatedOnly: false
    } as NavigationButton,
    {
      icon: 'fa fa-cogs',
      title: 'Einstellungen',
      isAvailable: false,
      link: 'settings',
      showLoggedOut: false,
      showLoggedIn: true,
      loggedInElevatedOnly: true
    } as NavigationButton,
    {
      icon: 'fa fa-user-shield',
      title: 'Admin',
      isAvailable: true,
      link: 'admin',
      showLoggedOut: false,
      showLoggedIn: true,
      loggedInElevatedOnly: true
    } as NavigationButton,
  ] as NavigationButton[],
  [
    {
      icon: 'fa fa-sign-out-alt',
      title: 'Logout',
      isAvailable: true,
      link: 'logout',
      showLoggedOut: false,
      showLoggedIn: true,
      loggedInElevatedOnly: false
    } as NavigationButton
  ] as NavigationButton[]
];

const App: React.FC = (): JSX.Element => {

  var {
    IsLoggedIn,
    CompanyName,
    UserName
  } = useLoginContext();

  return(
    <Router>
      <div className="App">
        { IsLoggedIn && <Navigation groups={ navigationButtonGroups } /> }
        {
          IsLoggedIn &&
          <AppHeader 
            title={ 'Dashboard' } 
            titleRight={ (CompanyName ? `${CompanyName}\\${UserName}` : '') } 
            />
        }
        {
          <Routes>
            { IsLoggedIn && <Route path="/dashboard" element={<DashboardView/>} /> }
            { IsLoggedIn && <Route path="/logout" element={<LogoutView/>} />}
            { IsLoggedIn && <Route path="/" element={<DashboardView/>} /> }
            { !IsLoggedIn && <Route path="/" element={<LoginView />} /> }
          </Routes>
          }
      </div>
    </Router>
  )
}

export default App;